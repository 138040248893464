<template>
  <div>
    <template>
      <v-data-table
        :headers="indicatorHeaders"
        :items="items"
        item-key="id"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>مقاييس</v-toolbar-title>
            <v-spacer></v-spacer>
            <IndicatorForm
              @closeDialog="openDialog0 = false"
              @formSubmitted="formSubmitted"
              :formTitle="formTitle"
              :dialog="openDialog0"
              :record="record"
              :id="id"
              v-if="openDialog0"
            />
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="dialogOpen(null, 'مقياس', null)"
            >
              <v-icon small>mdi-plus</v-icon>
            </v-btn>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline"
                  >Are you sure you want to delete this item?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm()"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template
          v-slot:item.actions="{ item }"
          style="textAlign:right !important"
        >
          <!-- <v-btn to="posts/add" text class="pa-0"> -->
          <v-icon
            color="primary"
            small
            class="mr-2"
            @click="dialogOpen(item, 'مقياس', null)"
          >
            mdi-pencil
          </v-icon>
          <!-- </v-btn> -->

          <v-icon color="error" small class="mr-2" @click="deleteItem(item)">
            mdi-delete
          </v-icon>
          <!-- <v-icon
            color="error"
            small
            class="mr-2"
            @click="dialogOpen(null, 'result', goToResults(item))"
          >
            mdi-plus
          </v-icon> -->
          <v-btn
            small
            color="success"
            @click="dialogOpen(null, 'result', goToResults(item))"
            style="margin: 20px;"
          >
            النتائج
          </v-btn>
          <v-btn
            small
            color="info"
            @click="dialogOpen(null, 'group', goToGroups(item))"
            style="margin: 20px;"
          >
            المجموعات
          </v-btn>
          <!-- <v-icon
            color="error"
            small
            class="mr-2"
            @click="dialogOpen(null, 'group', goToGroups(item))"
          >
            mdi-plus
          </v-icon> -->
        </template>
      </v-data-table>
    </template>
    <v-snackbar
      v-model="snackbar"
      absolute
      top
      :multi-line="true"
      :color="snackbarColor"
    >
      {{ snackbarMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import QuestionForm from "./QuestionForm";
import GroupForm from "./GroupForm";
import IndicatorForm from "./IndicatorForm";
import ResultForm from "./IndicatorResults";
import ApiService from "@/services/api.service";

export default {
  components: {
    QuestionForm,
    GroupForm,
    IndicatorForm,
    ResultForm,
  },
  data: () => ({
    snackbar: false,
    snackbarMsg: null,
    snackbarColor: "success",
    loadingGroup: true,
    id: null,
    openDialog0: false,
    openDialog: false,
    openDialog1: false,
    openDialog2: false,
    dialogDelete: false,
    formTitle: "إضافة عنصر جديد",
    record: null,
    items: [],
    singleExpand: false,
    singleExpand1: false,
    singleExpand2: false,
    singleExpand3: false,
    groupHeaders: [
      {
        text: "title",
        align: "start",
        sortable: false,
        value: "title",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    questionHeaders: [
      {
        text: "title",
        align: "start",
        sortable: false,
        value: "title",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    resultHeaders: [
      {
        text: "title",
        align: "start",
        sortable: false,
        value: "title",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    indicatorHeaders: [
      {
        text: "المقياس",
        align: "start",
        sortable: false,
        value: "title",
      },
      { text: "قبل الخصم", value: "before_discount" },
      { text: "بعد الخصم", value: "after_discount" },
      { text: "", value: "actions", sortable: false },
    ],
  }),
  methods: {
    goToGroups(item) {
      this.$router.push(`/groups/add/${item.id}`);
    },
    goToResults(item) {
      this.$router.push(`/results/add/${item.id}`);
    },
    async getIndicators() {
      try {
        this.loadingGroup = true;
        // &filter[group_id.question_id.result_id.parent_id][empty]
        const { data } = await ApiService.get(
          `items/courses?fields=id,enend_info,arend_info,enintro_info,intro_info,intro_video_link,title,about,short_discription,entitle,enabout,enshort_discription,type_id,instructor_id,en_instructor_id,currency_id,before_discount,after_discount,number_of_hours,hours_description,enhours_description,video_link,start_date,featured_image,attachment,certificate,category_id.category_id,category_id.id,tag_id.id,tag_id.tag_id,language_id.id,group_id.id,group_id.title,group_id.answer_value.id,group_id.answer_value.value,group_id.question_id.title,group_id.question_id.group_id,group_id.question_id.video_link,group_id.question_id.order,group_id.question_id.answer_id.title,group_id.question_id.answer_id.value,group_id.question_id.answer_id.order,group_id.question_id.id,group_id.question_id.answer_id.image&filter[type_id]=2`
        );
        this.items = data.data;
        console.log(this.items);
        this.loadingGroup = false;
      } catch (error) {
        console.log(error);
      }
    },
    toast(toaster, v, msg, append = false) {
      this.counter++;
      this.$bvToast.toast(msg, {
        title: `Alert`,
        toaster: toaster,
        solid: true,
        variant: v,
        appendToast: append,
      });
    },
    async loadData() {
      try {
        this.loading = true;
        // doce to load data ...
        this.getIndicators();
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    dialogOpen(item, type, id) {
      this.record = item;
      this.id = id;
      if (item) {
        this.formTitle = `تعديل ${type}`;
      } else {
        this.formTitle = `إضافة ${type}`;
      }
      if (type == "مقياس") {
        this.openDialog0 = true;
      } else if (type == "group") {
        this.openDialog = true;
      } else if (type == "question") {
        this.openDialog1 = true;
      } else if (type == "result") {
        this.openDialog2 = true;
      }
    },
    formSubmitted(msg) {
      // this.openDialog = false;
      // this.openDialog1 = false;
      // this.openDialog2 = false;
      this.openDialog0 = false;
      this.openDialog2 = false;
      this.snackbarMsg = msg;
      this.snackbar = true;
      // this.openEditDialog = false;
      // this.openAddDialog = false;
      // this.toast("b-toaster-top-center", "success", msg);
      alert("ok");
      this.loadData();
    },
    deleteItem(record) {
      this.record = record;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      try {
        this.loading = true;
        const { data } = await ApiService.delete(
          `items/courses/${this.record.id}`
        );
        this.loading = false;
        this.closeDelete();
        let msg;
        msg = "deleted successfully";
        // }
        alert(msg);
        this.loadData();
      } catch (error) {
        console.log(error);
      }
    },

    closeDelete() {
      this.dialogDelete = false;
    },
  },
  mounted() {
    this.getIndicators();
  },
};
</script>
