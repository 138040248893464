<template>
  <v-dialog v-model="dialog" max-width="60%" persistent>
    <v-card class="pa-7">
      <v-card-title>
        <span color="primary" class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" :valid="valid">
          <v-row>
            <v-col md="6">
              <v-text-field
                placeholder="title"
                label="العنوان"
                v-model="courseForm.title"
                :rules="[(v) => !!v || 'title is required']"
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                placeholder="title"
                label="title"
                v-model="courseForm.entitle"
                :rules="[(v) => !!v || 'title is required']"
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                placeholder="وصف قصير"
                label="وصف قصير"
                v-model="courseForm.short_discription"
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                placeholder="short description"
                label="short description"
                v-model="courseForm.enshort_discription"
              />
            </v-col>
            <v-col md="12">
              <v-textarea
                v-model="courseForm.about"
                counter
                label="عن"
              ></v-textarea>
            </v-col>
            <v-col md="12">
              <v-textarea
                v-model="courseForm.enabout"
                counter
                label="about"
              ></v-textarea>
            </v-col>
            <v-col md="12">
              <quill-editor
                v-model="courseForm.intro_info"
                ref="myQuillEditor"
                :options="editorOption"
                :rules="[
                  (v) => !!(v && v.length > 0) || 'please provide body content',
                ]"
              />
            </v-col>
            <v-col md="12">
              <quill-editor
                v-model="courseForm.enintro_info"
                ref="myQuillEditor"
                :options="editorOption"
                :rules="[
                  (v) => !!(v && v.length > 0) || 'please provide body content',
                ]"
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                placeholder="intro video link"
                label="intro video link"
                v-model="courseForm.intro_video_link"
              />
            </v-col>
            <v-col md="4">
              <v-autocomplete
                :loading="loadingTypes"
                v-model="courseForm.type_id"
                :items="types"
                dense
                chips
                small-chips
                label="النوع"
                item-text="title"
                item-value="id"
              ></v-autocomplete>
            </v-col>
            <v-col md="4">
              <v-autocomplete
                :loading="loadingCats"
                v-model="courseCats"
                :items="categories"
                dense
                chips
                small-chips
                label="التصنيف"
                multiple
                item-text="title"
                item-value="id"
              ></v-autocomplete>
            </v-col>
            <v-col md="4">
              <v-autocomplete
                :loading="loadingTags"
                v-model="courseTags"
                :items="tags"
                dense
                chips
                small-chips
                label="الوسم"
                multiple
                item-text="title"
                item-value="id"
              ></v-autocomplete>
            </v-col>
            <v-col md="4">
              <v-autocomplete
                :loading="loadingLanguages"
                v-model="courseForm.language_id"
                :items="languages"
                dense
                chips
                small-chips
                label="اللغة"
                item-text="title"
                item-value="id"
              ></v-autocomplete>
            </v-col>
            <v-col md="4">
              <v-autocomplete
                :loading="loadingInstructors"
                v-model="courseForm.instructor_id"
                :items="instructors"
                dense
                chips
                small-chips
                label="المحاضر"
                item-text="first_name"
                item-value="id"
              ></v-autocomplete>
            </v-col>

            <v-col md="4">
              <v-autocomplete
                :loading="loadingInstructors"
                v-model="courseForm.en_instructor_id"
                :items="instructors"
                dense
                chips
                small-chips
                label="instructor"
                item-text="first_name"
                item-value="id"
              ></v-autocomplete>
            </v-col>

            <v-col md="4">
              <v-autocomplete
                :loading="loadingCurrencies"
                v-model="courseForm.currency_id"
                :items="currencies"
                dense
                chips
                small-chips
                label="العملة"
                item-text="title"
                item-value="id"
              ></v-autocomplete>
            </v-col>
            <v-col md="6">
              <v-text-field
                placeholder="before discount"
                label="قبل الخصم"
                v-model="courseForm.before_discount"
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                placeholder="after discount"
                label="بعد الخصم"
                v-model="courseForm.after_discount"
              />
            </v-col>
            <v-col md="2">
              <v-file-input
                v-model="image"
                chips
                label="أختر صورة مفضلة"
              ></v-file-input>
            </v-col>
            <v-col md="2">
              <div v-if="file" class="image-area" style="margin-bottom: 10px;">
                <img
                  :src="file.data.full_url"
                  v-if="file"
                  width="250px"
                  height="250px"
                />
                <!-- @click="openLink(img.data.full_url)" -->

                <a
                  class="remove-image"
                  @click="removeimg(file.id, 'file')"
                  href="#"
                  style="display: inline;"
                  >&#215;</a
                >
              </div>
            </v-col>

            <v-col md="2">
              <v-file-input
                v-model="attachment"
                chips
                label="مرفقات"
              ></v-file-input>
            </v-col>
            <v-col md="2">
              <div v-if="file1" class="image-area" style="margin-bottom: 10px;">
                <img
                  :src="file1.data.full_url"
                  v-if="file1"
                  width="250px"
                  height="250px"
                />
                <!-- @click="openLink(img.data.full_url)" -->

                <a
                  class="remove-image"
                  @click="removeimg(file1.id, 'file1')"
                  href="#"
                  style="display: inline;"
                  >&#215;</a
                >
              </div>
            </v-col>

            <v-col md="2">
              <v-file-input
                v-model="certificate"
                chips
                label="شهادة"
              ></v-file-input>
            </v-col>
            <v-col md="2">
              <div v-if="file2" class="image-area" style="margin-bottom: 10px;">
                <img
                  :src="file2.data.full_url"
                  v-if="file2"
                  width="250px"
                  height="250px"
                />
                <!-- @click="openLink(img.data.full_url)" -->

                <a
                  class="remove-image"
                  @click="removeimg(file2.id, 'file2')"
                  href="#"
                  style="display: inline;"
                  >&#215;</a
                >
              </div>
            </v-col>

            <v-col md="4">
              <v-text-field
                placeholder="number of hours"
                label="عدد الساعات"
                v-model="courseForm.number_of_hours"
              />
            </v-col>
            <v-col md="4">
              <v-text-field
                placeholder="وصف الساعات"
                label="وصف الساعات"
                v-model="courseForm.hours_description"
              />
            </v-col>
            <v-col md="4">
              <v-text-field
                placeholder="number of hours notes"
                label="number of hours notes"
                v-model="courseForm.enhours_description"
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                placeholder="video link"
                label="رابط الفيديو"
                v-model="courseForm.video_link"
              />
            </v-col>
            <v-col md="6">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="courseForm.start_date"
                    label="تاريخ المقياس "
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="courseForm.start_date"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!--  -->
            <v-col md="12">
              <quill-editor
                v-model="courseForm.arend_info"
                ref="myQuillEditor"
                :options="editorOption"
                :rules="[
                  (v) => !!(v && v.length > 0) || 'please provide body content',
                ]"
              />
            </v-col>
            <v-col md="12">
              <quill-editor
                v-model="courseForm.enend_info"
                ref="myQuillEditor"
                :options="editorOption"
                :rules="[
                  (v) => !!(v && v.length > 0) || 'please provide body content',
                ]"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <!-- <v-card-actions>
        <v-spacer />
        <v-btn @click="createCourse" color="primary">
          <v-icon>mdi-send</v-icon>
        </v-btn>
      </v-card-actions> -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="close">
          إلغاء
        </v-btn>
        <v-btn color="blue darken-1" text @click="submit">
          إرسال
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="formSubmitted">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <!-- snackbar -->
    <v-snackbar
      v-model="snackbar"
      absolute
      top
      :multi-line="true"
      :color="snackbarColor"
    >
      {{ snackbarMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  props: ["formTitle", "record", "dialog", "id"],

  data() {
    return {
      valid: false,
      snackbar: false,
      snackbarMsg: null,
      snackbarColor: "",
      editorOption: {
        // some quill options
      },
      courseForm: {
        id: null,
        title: null,
        about: null,
        short_discription: null,
        entitle: null,
        enabout: null,
        enshort_discription: null,
        type_id: null,
        instructor_id: null,
        en_instructor_id: null,
        enintro_info: null,
        arend_info: null,
        enend_info: null,
        intro_info: null,
        intro_video_link: null,
        currency_id: null,
        before_discount: null,
        after_discount: null,
        number_of_hours: null,
        video_link: null,
        start_date: new Date().toISOString().substr(0, 10),
        featured_image: null,
        attachment: null,
        certificate: null,
        language_id: null,
        hours_description: null,
        enhours_description: null,
      },
      sectionForm: {
        title: null,
        duration: null,
      },
      lecturesForm: {
        title: null,
        duration: null,
      },
      courseCats: [],
      courseTags: [],
      categories: [],
      tags: [],
      types: [],
      instructors: [],
      currencies: [],
      languages: [],
      loadingLanguages: false,
      loadingCurrencies: false,
      loadingCats: false,
      loadingTags: false,
      loadingInstructors: false,
      loadingTypes: false,
      formSubmitted: false,
      image: null,
      attachment: null,
      certificate: null,
      file: null,
      file1: null,
      file2: null,
      menu2: false,
      initialPayload: {},
    };
  },

  methods: {
    async removeimg(id, text) {
      const { data } = await ApiService.delete(`files/${id}`);
      switch (text) {
        case "file":
          this.file = null;
          this.courseForm["featured_image"] = null;
          break;
        case "file1":
          this.file1 = null;
          this.courseForm["attachment"] = null;
          break;
        case "file2":
          this.file2 = null;
          this.courseForm["certificate"] = null;
          break;
      }

      this.submit();
    },
    async updateRecord() {
      try {
        const payloadInfoChanged = {};
        Object.keys(this.sectionForm).forEach((key) => {
          if (Array.isArray(this.sectionForm[key])) {
            const isEqual = this.sectionForm[key].every((field) => {
              return this.initialPayload[key].includes(field);
            });

            if (!isEqual) payloadInfoChanged[key] = this.sectionForm[key];
          } else if (
            typeof this.sectionForm[key] === "object" &&
            this.sectionForm[key] !== null
          ) {
            Object.keys(this.sectionForm[key]).forEach((key2) => {
              if (
                this.initialPayload[key][key2] !== this.sectionForm[key][key2]
              ) {
                payloadInfoChanged[key][key2] = this.sectionForm[key][key2];
              }
            });
          } else if (this.initialPayload[key] !== this.sectionForm[key]) {
            // this.initialPayload[key] !== this.sectionForm[key];
            payloadInfoChanged[key] = this.sectionForm[key];
          }
        });

        // send requsts
        if (Object.keys(payloadInfoChanged).length > 0) {
          // send request ...
        }
      } catch (error) {
        console.log(error);
      }
    },
    async submit() {
      try {
        if (this.$refs.form.validate()) {
          this.formSubmitted = true;
          // send file
          if (this.image) {
            let formData = new FormData();
            formData.append("data", this.image);
            const fileReq = await ApiService.postFile(
              "files",
              formData,
              0,
              null
            );
            this.courseForm["featured_image"] = fileReq.data.data.id;
          }
          if (this.attachment) {
            let formData1 = new FormData();
            formData1.append("data", this.attachment);
            const attachmentReq = await ApiService.postFile(
              "files",
              formData1,
              0,
              null
            );
            this.courseForm["attachment"] = attachmentReq.data.data.id;
          }
          if (this.certificate) {
            let formData2 = new FormData();
            formData2.append("data", this.certificate);
            const certificateReq = await ApiService.postFile(
              "files",
              formData2,
              0,
              null
            );
            this.courseForm["certificate"] = certificateReq.data.data.id;
          }
          if (this.record) {
            // await this.updateRecord();
            const courseReq = await ApiService.update(
              "items/courses",
              this.courseForm.id,
              this.courseForm
            );
          } else {
            console.log(this.courseForm);
            // send course req
            const courseReq = await ApiService.post(
              "items/courses",
              this.courseForm
            );

            // send cats req
            for (let cat of this.categories) {
              const catReq = await ApiService.post("items/courses_categories", {
                course_id: courseReq.id,
                category_id: cat,
              });
            }

            // send tags req
            for (let tag of this.tags) {
              const catReq = await ApiService.post("items/courses_tags", {
                course_id: courseReq.id,
                tag_id: tag,
              });
            }
          }
          this.formSubmitted = false;
          const msg = this.record
            ? "record updated successfully"
            : "record inserted successfully";
          this.$emit("formSubmitted", msg);
        } else {
          this.snackbar = true;
          this.error = "please fill all required fields";
        }
      } catch (error) {
        if (error.message !== "") {
          this.error = error;
        } else {
          this.error = "sorry, something went wrong";
        }
        this.snackbar = true;
        console.log(error);
        this.formSubmitted = false;
      }
    },
    close() {
      this.$emit("closeDialog");
    },
    // async createCourse() {
    //   try {
    //     if (this.$refs.form.validate()) {
    //       this.formSubmitted = true;
    //       // send file
    //       let formData = new FormData();
    //       formData.append("data", this.image);
    //       console.log(formData);
    //       const fileReq = await ApiService.postFile("files", formData, 0, null);
    //       formData["data"] = this.attachment;
    //       console.log(formData);
    //       const attachmentReq = await ApiService.postFile(
    //         "files",
    //         formData,
    //         0,
    //         null
    //       );
    //       formData["data"] = this.certificate;
    //       console.log(formData);
    //       const certificateReq = await ApiService.postFile(
    //         "files",
    //         formData,
    //         0,
    //         null
    //       );
    //       // send course req
    //       this.courseForm["featured_image"] = fileReq.id;
    //       this.courseForm["attachment"] = attachmentReq.id;
    //       this.courseForm["certificate"] = certificateReq.id;
    //       // console.log(this.courseForm);
    //       const courseReq = await ApiService.post(
    //         "items/courses",
    //         this.courseForm
    //       );

    //       // send cats req
    //       for (let cat of this.categories) {
    //         const catReq = await ApiService.post("items/courses_categories", {
    //           course_id: courseReq.id,
    //           category_id: cat,
    //         });
    //       }

    //       // send tags req
    //       for (let tag of this.tags) {
    //         const catReq = await ApiService.post("items/courses_tags", {
    //           course_id: courseReq.id,
    //           tag_id: tag,
    //         });
    //       }

    //       this.formSubmitted = false;
    //     } else {
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    async getCats() {
      try {
        this.loadingCats = true;
        const { data } = await ApiService.get(
          "items/categories?fields=id,title"
        );
        this.categories = data.data;
        this.loadingCats = false;
      } catch (error) {
        console.log(error);
      }
    },
    async getTags() {
      try {
        this.loadingTags = true;
        const { data } = await ApiService.get("items/tags?fields=id,title");
        this.tags = data.data;
        this.loadingTags = false;
      } catch (error) {
        console.log(error);
      }
    },
    async getTypes() {
      try {
        this.loadingTypes = true;
        const { data } = await ApiService.get("items/types?fields=id,title");
        this.types = data.data;
        this.loadingTypes = false;
      } catch (error) {
        console.log(error);
      }
    },
    async getInstructors() {
      try {
        this.loadingInstructors = true;
        const { data } = await ApiService.get("users?fields=id,first_name");
        this.instructors = data.data;
        this.loadingInstructors = false;
      } catch (error) {
        console.log(error);
      }
    },
    async getCurrencies() {
      try {
        this.loadingCurrencies = true;
        const { data } = await ApiService.get("items/currency?fields=id,title");
        this.currencies = data.data;
        this.loadingCurrencies = false;
      } catch (error) {
        console.log(error);
      }
    },
    async getLanguages() {
      try {
        this.loadingLanguages = true;
        const { data } = await ApiService.get(
          "items/languages?fields=id,title"
        );
        this.languages = data.data;
        this.loadingLanguages = false;
      } catch (error) {
        console.log(error);
      }
    },
  },

  async mounted() {
    await this.getCats();
    await this.getCurrencies();
    await this.getTags();
    await this.getTypes();
    await this.getInstructors();
    await this.getLanguages();
    // this.record && Object.assign(this.courseForm, this.record);
    if (this.record) {
      this.record.category_id.forEach((element) => {
        this.courseCats.push(element.category_id);
      });
      this.record.tag_id.forEach((element) => {
        this.courseTags.push(element.tag_id);
      });
      if (this.record) {
        Object.keys(this.courseForm).forEach((element) => {
          if (this.record[element]) {
            if (typeof this.record[element] == "object") {
              this.courseForm[element] = this.record[element].id;
            } else {
              this.courseForm[element] = this.record[element];
            }
          }
        });
        console.log(this.courseForm);
        if (this.courseForm.featured_image) {
          const { data } = await ApiService.getFile(
            "files",
            this.courseForm.featured_image
          );
          this.file = data.data;
        }

        if (this.courseForm.attachment) {
          console.log(this.courseForm.attachment);

          // await this.getFile(this.courseForm.image);
          const { data } = await ApiService.getFile(
            "files",
            this.courseForm.attachment
          );
          this.file1 = data.data;
        }

        if (this.courseForm.certificate) {
          // await this.getFile(this.courseForm.image);
          const { data } = await ApiService.getFile(
            "files",
            this.courseForm.certificate
          );
          this.file2 = data.data;
        }
      }
    }

    // Object.assign(this.initialPayload, this.record);
  },
};
</script>

<style lang="css">
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import "~quill/dist/quill.bubble.css";
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
.image-area {
  position: relative;
  width: 50%;
  background: #333;
}
.image-area img {
  max-width: 100%;
  height: auto;
}
.remove-image {
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 10em;
  padding: 2px 6px 3px;
  text-decoration: none;
  font: 700 21px/20px sans-serif;
  background: #555;
  border: 3px solid #fff;
  color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}
.remove-image:hover {
  background: #e54e4e;
  padding: 3px 7px 5px;
  top: -11px;
  right: -11px;
}
.remove-image:active {
  background: #e54e4e;
  top: -10px;
  right: -11px;
}
</style>
