<template>
  <v-form ref="form" :valid="valid">
    <v-row>
      <v-col md="4">
        <v-text-field
          placeholder="الترتيب"
          label="الترتيب"
          v-model="answerForm.order"
          :rules="[(v) => !!v || 'value is required']"
        />
      </v-col>
      <v-col md="2">
        <v-file-input v-model="image" chips label="صورة الجواب"></v-file-input>
      </v-col>
      <v-col md="2">
        <div v-if="file" class="image-area" style="margin-bottom: 10px;">
          <img
            :src="file.data.full_url"
            v-if="file"
            width="250px"
            height="250px"
          />
          <!-- @click="openLink(img.data.full_url)" -->

          <a
            class="remove-image"
            @click="removeimg(file.id, 'file')"
            href="#"
            style="display: inline;"
            >&#215;</a
          >
        </div>
      </v-col>
      <v-col md="4">
        <v-autocomplete
          :loading="loadingValues"
          v-model="answerForm.value"
          :items="values"
          dense
          chips
          small-chips
          label="قيمة الإجابة"
          item-text="value"
          item-value="id"
        ></v-autocomplete>
      </v-col>
      <v-col md="12">
        <quill-editor
          v-model="answerForm.title"
          ref="myQuillEditor"
          :options="editorOption"
          :rules="[
            (v) => !!(v && v.length > 0) || 'please provide body content',
          ]"
        />
      </v-col>
      <v-col md="12">
        <quill-editor
          style="direction:ltr"
          v-model="answerForm.entitle"
          ref="myQuillEditor"
          :options="editorOption"
          :rules="[
            (v) => !!(v && v.length > 0) || 'please provide body content',
          ]"
        />
      </v-col>
      <v-col md="12">
        <quill-editor
          v-model="answerForm.readmore"
          ref="myQuillEditor"
          :options="editorOption"
          :rules="[
            (v) => !!(v && v.length > 0) || 'please provide body content',
          ]"
        />
      </v-col>
      <v-col md="12">
        <quill-editor
          style="direction:ltr"
          v-model="answerForm.enreadmore"
          ref="myQuillEditor"
          :options="editorOption"
          :rules="[
            (v) => !!(v && v.length > 0) || 'please provide body content',
          ]"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="10">
        <v-overlay :value="submitted">
          <v-progress-circular
            indeterminate
            size="64"
          ></v-progress-circular> </v-overlay
      ></v-col>
      <v-col cols="12" md="2">
        <v-btn color="success" @click="submit">
          إرسال
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-data-table
          :headers="answerHeader"
          :items="answers"
          :items-per-page="5"
          class="elevation-1 "
        >
          <template v-slot:top>
            <v-toolbar flat class="mb-7">
              <!-- delete item -->
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline"
                    >Are you sure you want to delete this user?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="deleteItemConfirm()"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template
            v-slot:item.actions="{ item }"
            style="textAlign:right !important"
          >
            <v-icon
              color="primary"
              small
              class="mr-2"
              @click="editAnswer(item)"
            >
              mdi-pencil
            </v-icon>

            <v-icon
              color="error"
              small
              class="mr-2"
              @click="deleteAnswer(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  props: ["formTitle", "dialog", "question_id"],
  data() {
    return {
      loadingValues: false,
      snackbar: false,
      valid: false,
      submitted: false,
      loading: false,
      dialogDelete: false,
      group_id: null,
      answers: [],
      values: [],
      answerHeader: [
        {
          text: "الجواب",
          value: "title",
        },
        {
          text: "الجواب",
          value: "image.data.full_url",
        },
        { text: "", value: "actions", sortable: false },
      ],
      error: "",
      answerForm: {
        id: null,
        value: null,
        question_id: null,
        title: null,
        entitle: null,
        order: null,
        value: null,
        image: null,
        readmore: null,
        enreadmore: null,
      },
      editorOption: {
        // some quill options
      },
      image: null,
      file: null,
      record: null,
      textRules: [(v) => !!v || "field is required"],

      // =================== for updating users ===================
      initialPayload: {},
    };
  },
  methods: {
    deleteAnswer(record) {
      this.record = record;
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      try {
        this.loading = true;
        await ApiService.delete(`items/answers/${this.record.id}`);
        this.loadData();
        this.loading = false;
        this.closeDelete();

        alert("Product deleted successfully");
        this.loadData();
      } catch (error) {
        console.log(error);
      }
    },
    closeDelete() {
      this.record = null;
      this.dialogDelete = false;
    },
    async editAnswer(item) {
      this.reset();
      Object.keys(this.answerForm).forEach((element) => {
        if (item[element]) {
          if (typeof item[element] == "object") {
            this.answerForm[element] = item[element].id;
          } else {
            this.answerForm[element] = item[element];
          }
        }
      });
      if (this.answerForm.image) {
        const { data } = await ApiService.getFile(
          "files",
          this.answerForm.image
        );
        this.file = data.data;
      }
      console.log(this.answerForm);
    },
    async submit() {
      try {
        if (this.$refs.form.validate()) {
          this.submitted = true;
          let formData = new FormData();
          if (this.image) {
            formData.append("data", this.image);
            const fileReq = await ApiService.postFile(
              "files",
              formData,
              0,
              null
            );
            this.answerForm["image"] = fileReq.data.data.id;
          }
          if (this.answerForm.id) {
            console.log("update : ", this.answerForm);

            const answerReq = await ApiService.update(
              "items/answers",
              this.answerForm.id,
              this.answerForm
            );
          } else {
            this.answerForm.question_id = this.question_id;
            console.log(this.answerForm);
            const answerReq = await ApiService.post(
              "items/answers",
              this.answerForm
            );
          }
          this.submitted = false;
          this.loadData();
          // this.$emit("formSubmitted", "categories ok");
          const msg = this.record
            ? "record updated successfully"
            : "record inserted successfully";
          this.$emit("formSubmitted", this.question_id, msg);
        } else {
          this.snackbar = true;
          this.error = "please fill all required fields";
        }
      } catch (error) {
        if (error.message !== "") {
          this.error = error;
        } else {
          this.error = "sorry, something went wrong";
        }
        this.snackbar = true;
        console.log(error);
        this.submitted = false;
      }
    },
    reset() {
      this.answerForm.id = null;
      this.answerForm.value = null;
      this.answerForm.question_id = null;
      this.answerForm.title = null;
      this.answerForm.entitle = null;
      this.answerForm.enreadmore = null;
      this.answerForm.readmore = null;
      this.answerForm.order = null;
      this.answerForm.value = null;
      this.answerForm.image = null;
    },
    close() {
      this.$emit("closeDialog");
    },
    async loadData() {
      this.loading = true;
      this.reset();
      const answerReq = await ApiService.get(
        `items/answers?fields=id,question_id,value,title,entitle,readmore,enreadmore,order,image.data.full_url&filter[question_id]=${this.question_id}`
      );
      this.answers = answerReq.data.data;
      const questionReq = await ApiService.get(
        `items/questions?fields=group_id&filter[id]=${this.question_id}`
      );
      this.group_id = questionReq.data.data[0].group_id;
      console.log(questionReq.data.data);
      this.getvalues();
    },
    async getvalues() {
      try {
        this.loadingValues = true;
        const { data } = await ApiService.get(
          `items/groups_answers_value?fields=id,value&filter[group_id]=${this.group_id}`
        );
        this.values = data.data;
        this.loadingValues = false;
      } catch (error) {
        console.log(error);
      }
    },
  },

  async mounted() {
    // this.getvalues();
  },
};
</script>

<style lang="css">
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import "~quill/dist/quill.bubble.css";
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
.image-area {
  position: relative;
  width: 50%;
  background: #333;
}
.image-area img {
  max-width: 100%;
  height: auto;
}
.remove-image {
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 10em;
  padding: 2px 6px 3px;
  text-decoration: none;
  font: 700 21px/20px sans-serif;
  background: #555;
  border: 3px solid #fff;
  color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}
.remove-image:hover {
  background: #e54e4e;
  padding: 3px 7px 5px;
  top: -11px;
  right: -11px;
}
.remove-image:active {
  background: #e54e4e;
  top: -10px;
  right: -11px;
}
</style>
