<template>
  <v-form ref="form" :valid="valid">
    <v-row>
      <v-col md="4">
        <v-text-field
          placeholder="الترتيب"
          label="الترتيب"
          v-model="questionForm.order"
          :rules="[(v) => !!v || 'order is required']"
        />
      </v-col>
      <v-col md="8">
        <v-text-field
          placeholder="رابط الفيديو"
          label="رابط الفيديو"
          v-model="questionForm.video_link"
        />
      </v-col>
      <v-col md="12">
        <quill-editor
          v-model="questionForm.title"
          ref="myQuillEditor"
          :options="editorOption"
          :rules="[
            (v) => !!(v && v.length > 0) || 'please provide body content',
          ]"
        />
      </v-col>
      <v-col md="12">
        <quill-editor
          style="direction:ltr"
          v-model="questionForm.entitle"
          ref="myQuillEditor"
          :options="editorOption"
          :rules="[
            (v) => !!(v && v.length > 0) || 'please provide body content',
          ]"
        />
      </v-col>
      <v-col>
        <quill-editor
          label="read more"
          v-model="questionForm.readmore"
          ref="myQuillEditor"
          :options="editorOption"
          :rules="[
            (v) => !!(v && v.length > 0) || 'please provide body content',
          ]"
        />
      </v-col>
      <v-col md="12">
        <quill-editor
          style="direction:ltr"
          v-model="questionForm.enreadmore"
          ref="myQuillEditor"
          :options="editorOption"
          :rules="[
            (v) => !!(v && v.length > 0) || 'please provide body content',
          ]"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="10">
        <v-overlay :value="submitted">
          <v-progress-circular
            indeterminate
            size="64"
          ></v-progress-circular> </v-overlay
      ></v-col>
      <v-col cols="12" md="2">
        <v-btn color="success" @click="submit">
          إرسال
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import ApiService from "@/services/api.service";
// import { mapActions } from "vuex";

export default {
  props: ["formTitle", "record", "dialog", "group_id"],
  data() {
    return {
      loadingTypes: false,
      snackbar: false,
      valid: false,
      submitted: false,
      loading: false,
      error: "",
      questionForm: {
        id: null,
        title: null,
        entitle: null,
        video_link: null,
        order: null,
        group_id: null,
        readmore: null,
        enreadmore: null,
      },
      editorOption: {
        // some quill options
      },
      textRules: [(v) => !!v || "field is required"],

      // =================== for updating users ===================
      initialPayload: {},
    };
  },
  methods: {
    async removeimg(id, text) {
      const { data } = await ApiService.delete(`files/${id}`);
      this.file1 = null;
      this.questionForm["attachment"] = null;
      this.submit();
    },
    async submit() {
      try {
        if (this.$refs.form.validate()) {
          this.submitted = true;
          let questionReq;
          if (this.record) {
            questionReq = await ApiService.update(
              "items/questions",
              this.questionForm.id,
              this.questionForm
            );
          } else {
            this.questionForm.group_id = this.group_id;
            this.questionForm.section_id = this.id;
            questionReq = await ApiService.post(
              "items/questions",
              this.questionForm
            );
          }

          this.submitted = false;

          const msg = this.record
            ? "record updated successfully"
            : "record inserted successfully";
          this.$emit("formSubmitted", questionReq.data.data.id, msg);
        } else {
          this.snackbar = true;
          this.error = "please fill all required fields";
        }
      } catch (error) {
        if (error.message !== "") {
          this.error = error;
        } else {
          this.error = "sorry, something went wrong";
        }
        this.snackbar = true;
        console.log(error);
        this.submitted = false;
      }
    },
    close() {
      this.$emit("closeDialog");
    },
  },

  async mounted() {
    // this.record && Object.assign(this.questionForm, this.record);
    // Object.assign(this.initialPayload, this.record);
    if (this.record) {
      Object.keys(this.questionForm).forEach((element) => {
        if (this.record[element]) {
          if (typeof this.record[element] == "object") {
            this.questionForm[element] = this.record[element].id;
          } else {
            this.questionForm[element] = this.record[element];
          }
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>
