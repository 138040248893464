<template>
  <v-dialog v-model="dialog" max-width="900px" persistent>
    <v-card>
      <v-card-title>
        <span color="primary" class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-stepper v-model="e1" class="elevation-0 p-0 m-0">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1">
              السؤال
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="e1 > 2" step="2">
              الإجابات
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <QuestionData
                @formSubmitted="formSubmitted"
                :record="record"
                :group_id="group_id"
              />

              <v-btn color="primary" @click="next(2)" v-if="this.question_id">
                الإستمرار
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="2">
              <!-- ==================== chapters form ==================== -->
              <AnswerData
                @formSubmitted="formSubmitted"
                ref="AnswerData"
                :question_id="question_id"
                :group_id="group_id"
              />

              <v-btn text @click="back(1)">
                العودة
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="close">
          إلغاء
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- snackbar -->
    <v-snackbar
      v-model="snackbar"
      absolute
      top
      :multi-line="true"
      :color="snackbarColor"
    >
      {{ snackbarMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          إغلاق
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import QuestionData from "./QuestionData";
import AnswerData from "./AnswerData";

export default {
  components: {
    QuestionData,
    AnswerData,
  },
  props: ["formTitle", "record", "dialog", "id"],
  data() {
    return {
      snackbar: false,
      snackbarMsg: null,
      snackbarColor: "success",
      question_id: null,
      snackbar: false,
      e1: 1,
      submitted: false,
      loading: false,
      error: "",
      group_id: null,
      textRules: [(v) => !!v || "Name is required"],

      // =================== for update ===================
      initialPayload: {},
    };
  },
  methods: {
    next(id) {
      switch (id) {
        case 2:
          if (this.question_id) {
            this.$refs.AnswerData.loadData();
            this.e1 = id;
          } else {
            alert("you must insert or select product");
          }
          break;
      }
    },
    back(id) {
      switch (id) {
        case 1:
          this.e1 = id;
          break;
      }
    },

    close() {
      this.$emit("closeDialog");
    },
    formSubmitted(id, msg) {
      this.question_id = id;
      this.snackbarMsg = msg;
      this.snackbar = true;
      this.$emit("formSubmitted", msg);
    },
  },

  async mounted() {
    if (this.record) {
      this.question_id = this.record.id;
      this.group_id = this.record.group_id.id;
    } else {
      this.group_id = this.id;
    }
  },
};
</script>
<style lang="css">
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import "~quill/dist/quill.bubble.css";
.image-area {
  position: relative;
  width: 50%;
  background: #333;
}
.image-area img {
  max-width: 100%;
  height: auto;
}
.remove-image {
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 10em;
  padding: 2px 6px 3px;
  text-decoration: none;
  font: 700 21px/20px sans-serif;
  background: #555;
  border: 3px solid #fff;
  color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}
.remove-image:hover {
  background: #e54e4e;
  padding: 3px 7px 5px;
  top: -11px;
  right: -11px;
}
.remove-image:active {
  background: #e54e4e;
  top: -10px;
  right: -11px;
}
</style>
